import React, { Component } from "react";
import { withRouter } from "react-router";

class LoginTerms extends Component {
  render() {
    return (
      <div className="root_loginTerms">
        <div className="hdr_loginTerms">TERMS AND CONDITIONS</div>
        <div className="body_loginTerms">
          User Agreement
          <br />
          <br /> A. INFORMATION SUPPLIED BY USERS Registration data: <br />
          <br />
          These Terms of Use govern your use of the websites, content and
          community services offered through www.wineasy.in ("Site") any mobile
          or internet connected device or otherwise (the "the Service"). <br />
          <br />
          These terms of use (Terms) constitute a legally binding agreement
          between you and the Company regarding your use of the Site and any
          services offered by the company including but not limited to delivery
          of content via the Site, any mobile or internet connected device or
          otherwise (the "the Service"). By accessing the Site or Service and/or
          by clicking "I agree", you agree to be bound by these Terms. You
          hereby represent and warrant to the Company that you are at least
          eighteen (18) years of age or above and are capable of entering,
          performing and adhering to these Terms and that you agree to be bound
          by the following terms and conditions. While individuals under the age
          of 18 may utilize the Service of the site, they shall do so only with
          the involvement &amp; guidance of their parents and / or legal
          guardians, under such Parent /Legal guardian's registered account. You
          agree to register prior to uploading any content and / or comment and
          any other use or services of this site and provide your details
          including complete name, age bracket, gender, city and contact number.
          <br />
          <br />
          Definition of "User" or "You": means any person who access or avail
          this site of the Company for the purpose of hosting, publishing,
          sharing, transacting, displaying or uploading information or views and
          includes other persons jointly participating in using the site of the
          Company. <br />
          <br />
          BCCL may add to or change or update these Terms of Use, from time to
          time entirely at its own discretion. You are responsible for checking
          these Terms of Use periodically to remain in compliance with these
          terms. Your use of a Site after any amendment to the Terms of Use
          shall constitute your acceptance of these terms and you also agree to
          be bound by any such changes/revisions. <br />
          <br />
          BCCL may add to or change or update these Terms of Use, from time to
          time entirely at its own discretion. You are responsible for checking
          these Terms of Use periodically to remain in compliance with these
          terms. Your use of a Site after any amendment to the Terms of Use
          shall constitute your acceptance of these terms and you also agree to
          be bound by any such changes/revisions. <br />
          <br />
          Changes
          <br />
          <br /> BCCL reserves the right to suspend / cancel, or discontinue any
          or all channels, products or service at any time without notice , make
          modifications and alterations in any or all of the content, products
          and services contained on the Site without prior notice. Such changes
          will be posted to the Site for your reference and convenience so as to
          enable you to understand your responsibility as a user.
          <br />
          <br /> Copyright and Trademarks
          <br />
          <br /> Unless otherwise stated, copyright and all intellectual
          property rights in all material presented on the site (including but
          not limited to text, audio, video or graphical images), trademarks and
          logos appearing on this site are the property of Bennett, Coleman
          &amp; Company Limited, its parent, affiliates and associates and are
          protected under applicable Indian laws. You agree not to use any
          framing techniques to enclose any trademark or logo or other
          proprietary information of BCCL; or remove, conceal or obliterate any
          copyright or other proprietary notice or any credit-line or date-line
          on other mark or source identifier included on the Site / Service,
          including without limitation, the size, color, location or style of
          all proprietary marks. Any infringement shall be vigorously defended
          and pursued to the fullest extent permitted by law. <br />
          <br />
          Limited Permission to Copy
          <br />
          <br /> BCCL grants you permission to only access and make personal use
          of the Site and You agree not to, directly or indirectly download or
          modify / alter / change / amend / vary / transform / revise /
          translate / copy / publish / distribute or otherwise disseminate any
          content on BCCL's Site / Service, or any portion of it; or delete or
          fail to display any promotional taglines included in the Site /
          Service either directly or indirectly, except with the express consent
          of BCCL. However, you may print or download extracts from these pages
          for your personal / individual, non-commercial use only. You must not
          retain any copies of these pages saved to disk or to any other storage
          medium except for the purposes of using the same for subsequent
          viewing purposes or to print extracts for personal / individual use.
          <br />
          <br />
          BCCL forbids you from any attempts to resell or put to commercial use
          any part of the Site; any collection and use of any product listings,
          descriptions, or prices; any derivative use of the Site or its
          contents; any downloading or copying of account information for the
          benefit of any other merchant; any renting, leasing, or otherwise
          transferring rights to the Site / Service; displaying the name, logo,
          trademark or other identifier of another person (except for
          indiatimes.com or you) in such a manner as to give the viewer the
          impression that such other person is a publisher or distributor of the
          Service on the Site, or any data gathering or extraction tools; or any
          use of meta tags. You may not (whether directly or through the use of
          any software program) create a database in electronic or structured
          manual form by regularly or systematically downloading and storing all
          or any part of the pages from this site.
          <br />
          <br /> No part of the Site may be reproduced or transmitted to or
          stored in any other web site, nor may any of its pages or part thereof
          be disseminated in any electronic or non-electronic form, nor included
          in any public or private electronic retrieval system or service
          without prior written permission.
          <br />
          <br />
          Individual Registration, Access and Exchange of Information <br />
          <br />
          For certain services such as email, personal web pages, contests and
          shopping, registration by the visitor is required. To register for
          these services you have to open an account by completing the
          registration process (i.e. by providing us with current, complete and
          accurate information as prompted by the applicable registration form).
          You also will choose a password and a user name. You are entirely
          responsible for maintaining the confidentiality of your password and
          account. By registering, you agree to the following terms in addition
          to any other specific terms which shall be posted at an appropriate
          location of the Site. Each registration is for a single individual
          user only. To access these services, you will be asked to enter your
          individual User Name and Password, as chosen by you during your
          registration. Therefore, we do not permit any of the following:- Any
          other person sharing your account and Password; Any part of the Site
          being cached in proxy servers and accessed by individuals who have not
          registered with BCCL as users of the Site; or Access through a single
          account and Password being made available to multiple users on a
          network. <br />
          <br />
          If BCCL reasonably believe that an account and Password is being used
          / misused in any manner, BCCL shall reserve the right to cancel access
          rights immediately without notice, and block access to all users from
          that IP address.
          <br />
          <br /> Furthermore, you are entirely responsible for any and all
          activities that occur under your account. You agree to notify BCCL
          immediately of any unauthorized use of your account or any other
          breach of security. BCCL will not be liable for any loss that you may
          incur as a result of someone else using your password or account.
          However, you could be held liable for losses incurred by BCCL or
          another party due to someone else using your account or password.
          <br />
          <br /> No unlawful or prohibited use <br />
          <br />
          As a condition of your use of the Services, you will not use the
          Services for any purpose that is unlawful or prohibited by these
          terms, conditions, and notices. You may not use the Services in any
          manner that could damage, disable, overburden, or impair any BCCL's
          server, or the network(s) connected to any BCCL server, or interfere
          with any other party's use and enjoyment of any Services. You may not
          attempt to gain unauthorized access to any Services, other accounts,
          computer systems or to any of the Services, through hacking, password
          mining or any other means. You may not obtain or attempt to obtain any
          materials or information through any means not intentionally made
          available through the Services. <br />
          <br />
          Material Posted/transmitted at BCCL's Site
          <br />
          <br /> All information, data, text, software, music, sound,
          photographs, graphics, video, messages or other materials ("Content"),
          whether publicly or privately transmitted / posted, is the sole
          responsibility of the person from where such content is originated
          (the Originator). By Posting any material which contain images,
          photographs, pictures or that are otherwise graphical in whole or in
          part ("Images"), you warrant and represent that (a) you are the
          copyright owner of such Images, or that the copyright owner of such
          Images has granted you permission to use such Images or any content
          and/or images contained in such Images consistent with the manner and
          purpose of your use and as otherwise permitted by these Terms of Use
          and the Services, (b) you have the rights necessary to grant the
          licenses and sublicenses described in these Terms of Use, and (c) that
          each person depicted in such Images, if any, has provided consent to
          the use of the Images as set forth in these Terms of Use, including,
          by way of limitation, the distribution, public display and
          reproduction of such Images. <br />
          <br />
          You represent that you have valid rights and title in any and all
          Content/Images that you submit on the Site, that you have not
          infringed on any IPR belonging to any party and further that you will
          indemnify BCCL or its affiliates for all claims arising out of any
          content that you post on the Site. <br />
          <br />
          Posting/uploading of any information or material, which is harmful,
          defamatory, obscene, pornographic, libellous, invasive of another's
          privacy, profane, hateful, or racially, ethnically objectionable,
          disparaging, or otherwise unlawful in any manner is prohibited and any
          person found to be involved in such activity on this website will
          solely be liable for actions under the applicable laws. <br />
          <br />
          BCCL accepts no responsibility for the said Content / Images. However,
          you understand that all Content / Images posted by you becomes the
          property of BCCL and you agree to grant/assign to BCCL and its
          affiliates, a non-exclusive, royalty free, perpetual, irrevocable and
          sub-licenseable right to use, reproduce, modify, adapt, publish,
          translate, create derivative works from, distribute, perform and
          display such Content / Images (in whole or part) worldwide and/or to
          incorporate it in other works in any form, media, or technology now
          known or later developed throughout the world".
          <br />
          <br /> Exchange of Information qua CHAT &amp; other Community Services
          <br />
          <br /> You represent that you are a responsible adult (18 years or
          above) and you shall be solely responsible for all Content that you
          upload post or otherwise transmit. <br />
          <br />
          BCCL endeavours to provide a web platform for various community
          interactions for persons to interact and exchange views with each
          other. The content posted on such services is by general public
          therefore the accuracy, integrity or quality of such content cannot be
          guaranteed. You understand that by using such services, you may be
          exposed to objectionable matter.
          <br />
          <br /> By using the facility of chat and other community services you
          agree not to upload, post, or otherwise transmit any matter or views,
          which are, defamatory, abusive, pervasive, obscene, invasive of
          another's privacy, hateful, or racially, ethnically or otherwise
          objectionable. You may also not indulge in cyber stalking, become or
          create a nuisance for any visitor / user of the Site. <br />
          <br />
          You may not send, submit, post, or otherwise transmit, material or
          messages that contain software virus, or any other files that are
          designed to interrupt, destroy, and negatively affect in any manner
          whatsoever, any electronic equipment in connection with the use of
          this Site, or other user's ability to engage in real time exchanges.
          <br />
          <br />
          Prohibited Activities <br />
          <br />
          You shall not host, display, upload, modify, publish, transmit, update
          or share any information on the Site, that —<br />
          <br /> a) Belongs to another person and to which you do not have any
          right to;
          <br />
          <br /> b) Is grossly harmful, harassing, blasphemous, defamatory,
          obscene, pornographic, paedophilic, libellous, invasive of another's
          privacy, hateful, or racially, ethnically objectionable, disparaging,
          relating or encouraging money laundering or gambling, or otherwise
          unlawful in any manner whatever;
          <br />
          <br /> c) Harm minors in any way; <br />
          <br />
          d) Infringes any patent, trademark, copyright or other proprietary
          rights; <br />
          <br />
          e) Violates any law for the time being in force;
          <br />
          <br /> f) Deceives or misleads the addressee about the origin of such
          messages or communicates any information which is grossly offensive or
          menacing in nature; <br />
          <br />
          g) Impersonate another person <br />
          <br />
          h) Contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource; <br />
          <br />
          i) Threatens the unity, integrity, defence, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognisable offence or
          prevents investigation of any offence or is insulting any other
          nation. <br />
          <br />
          Any Content uploaded by you shall be subject to relevant laws and may
          disabled, or and may be subject to investigation under appropriate
          laws. Furthermore, if you are found to be in non-compliance with the
          laws and regulations, these terms, or the privacy policy of the Site,
          we may terminate your account/block your access to the Site and we
          reserve the right to remove any non-compliant Content uploaded by you.
          Any Content and or comment uploaded by you, shall be subject to
          relevant Indian laws and may be disabled, or and may be subject to
          investigation under appropriate laws. Furthermore, if you are found to
          be in non-compliance with the laws and regulations, these terms, or
          the privacy policy of the Site, the Company shall have the right to
          immediately terminate/block your access and usage of the Site and the
          Company shall have the right to immediately remove any non-compliant
          Content and or comment, uploaded by you and shall further have the
          right to take recourse to such remedies as would be available to the
          Company under the applicable laws. <br />
          <br />
          Grievance Redressal
          <br />
          <br /> http://wineasy.in <br />
          <br />
          The Times of India Building <br />
          <br />
          Dr.DN Road, Fort <br />
          <br />
          Mumbai – 400001
          <br />
          <br />
          Ph:022-66356635 <br />
          <br />
          The company shall not be responsible for any communication, if
          addressed, to any non-designated person in this regard. <br />
          <br />
          Limitations of Liabilities <br />
          <br />
          Please note that the Site is for information purpose only relating to
          the service of BCCL and the actual prices of the services may differ
          based on the relevant circumstances on case to case basis. Display of
          the pricing on any place of the Site or any third party website or app
          does not guarantee or bind BCCL to provide any services to the User at
          the same cost. All the data or information provided to the User
          through the Site are obtained and displayed to the User on ‘As-is’
          basis. User must consult the advertiser or company whose products have
          been displayed on the Site to take any of the mentioned services of
          his or her own choice to get the final negotiated quote. <br />
          <br />
          All information, products and services included on or otherwise made
          available to User through this Site are provided by BCCL on an ‘as is’
          and ‘as available’ basis, either expressed or implied, BCCL
          specifically disclaim warranties of any kind to the extent allowed by
          the applicable law. User expressly agrees that Use by the User of this
          Site is at his or her own risk. BCCL cannot guarantee the adequacy,
          currency or completeness of the Site content. BCCL does not warrant or
          endorse the effectiveness, quality or safety of any product or
          services available on the Site. <br />
          <br />
          Any transactions relating to sale/purchase of goods or services not
          directly offered by BCCL are to be settled inter-se between the
          parties to such transaction and all warranties express or implied of
          any kind, regarding any matter pertaining thereto, including without
          limitation the implied warranties of merchantability, fitness for a
          particular purpose, and non-infringement are disclaimed by BCCL. BCCL
          merely endeavors to provide a platform where you and other party may
          interact, exchange information or carry out sale/purchase transactions
          on such terms and in the manner mutually agreed between you and the
          other party. BCCL does not have any involvement in the actual
          transactions between the buyers and sellers in any way. It is only
          you, who is entirely responsible for all the activities, arising out
          of the transactions of sale/purchase of goods or services offered by
          any other party and not BCCL. BCCL will not be liable for any loss
          that you may incur, while selling or purchasing goods /services of the
          third party. <br />
          <br />
          Under No circumstances will BCCL be held responsible or liable, in any
          way, for any content which in Legal opinion is derogatory,
          threatening, defamatory, obscene or offensive or offends public
          sensibilities or morals and shall also not assume liability for any
          errors or omissions in any content, or for any loss or damage of any
          kind incurred as a result of the use of any content posted or uploaded
          on the Site, or any infringement of another's rights, including
          intellectual property rights. You specifically agree that BCCL is not
          responsible for any content sent using and/or included in BCCL's
          site/service by any third party. <br />
          <br />
          Any offer or competition or prize is subject to applicable laws and
          BCCL’s policies. BCCL does not guarantee to provide any offer or give
          any specific prizes for any competition. <br />
          <br />
          Termination of Account <br />
          <br />
          BCCL reserves its right to refuse service, restrict, suspend,terminate
          your account; (Terminate this Agreement; Terminate or suspend your
          access to the BCCL's Web Sites; Refuse, move or remove for any reason
          any Content / Image that you submit on or through the Services;
          Refuse, move, or remove any Content / Image that is available on or
          through the Services; Deactivate or delete your accounts and all
          related information and files in your account; Establish general
          practices and limits concerning use of the Services) at any time and,
          remove or edit contents or cancel orders (entered by you) in its sole
          discretion with or without cause, and with or without any prior notice
          for any violation of the Terms of Use. Upon such termination or
          suspension, your right to use the BCCL's Web Sites will immediately
          cease. <br />
          <br />
          You can also terminate your account at any time by intimating the same
          to the "Grievance Officer" but your information may remain stored in
          archive on our servers even after the deletion or the termination of
          your account. <br />
          <br />
          User Conduct and Obligations <br />
          <br />
          You hereby agree and assure BCCL that the Site/Services shall be used
          for lawful purposes only and that you will not violate laws,
          regulations, ordinances or other such requirements of any applicable
          Central, State or local government or any other international laws.
          You further concur that you will not, through Site/Service: <br />
          <br />
          1. post, distribute, or otherwise make available or transmit any
          software or other computer files that contain a virus trojan horses,
          time bombs, bots, botnets, malicious content, content theft, data
          manipulation, threats or any other harmful programs or elements or
          component;
          <br />
          <br /> 2. delete from the Site any legal notices, disclaimers, or
          proprietary notices such as copyright or trademark symbols, or modify
          any logos that you do not own or have express permission to modify;{" "}
          <br />
          <br />
          3. not use the Site / Service in any manner that could damage,
          disable, overburden, or impair and not to undertake any action which
          is harmful or potentially harmful to any BCCL's server, or the
          network(s), computer systems / resource connected to any BCCL server,
          or interfere with any other party's use and enjoyment of the
          Site/Service; <br />
          <br />
          4. obtain or attempt to obtain any materials or information through
          any means not intentionally made available through the Site/Service;
          <br />
          <br /> 5. engage in any activity that causes / may harm minors; or{" "}
          <br />
          <br />
          6. perform any activity which is likely to cause such harm; <br />
          <br />
          7. impersonate any person or entity, including, but not limited to,
          BCCL's official, forum leader, guide or host, or falsely state or
          otherwise misrepresent your affiliation with a person or entity;{" "}
          <br />
          <br />
          8. take any action which encourages or consists of any threat of harm
          of any kind to any person or property; <br />
          <br />
          9. carry out any "denial of service" (DoS, DDoS) or any other harmful
          attacks on application or internet service or; <br />
          <br />
          10. make any inappropriate, illegal or otherwise prohibited
          communication to any Newsgroup, Mailing List, Chat Facility, or other
          Internet Forum; <br />
          <br />
          11. use the Site/Service for illegal purposes; <br />
          <br />
          12. disrupt, place unreasonable burdens or excessive loads on,
          interfere with or attempt to make or attempt any unauthorized access
          to any BCCL website or the website of any BCCL's customer;
          <br />
          <br /> 13. transmit through the Site, any unlawful, harassing,
          libelous, abusive, threatening, harmful, vulgar, obscene, libelous,
          hateful, or racially, ethnically or otherwise objectionable material
          of any kind or nature. This includes text, graphics, video, programs
          or audio, etc.; <br />
          <br />
          14. collect or attempt to collect personally identifiable information
          of any person or entity without their express written consent and you
          shall maintain records of any such written consent throughout the
          terms of this agreement and for a period of 2 years thereafter; <br />
          <br />
          15. engage in antisocial, disruptive, or destructive acts, including
          "flaming," "spamming," "flooding," "trolling," and "griefing" as those
          terms are commonly understood and used on the Internet <br />
          <br />
          16. forge headers or otherwise manipulate identifiers in order to
          disguise the origin of any content transmitted through the Site.
          <br />
          <br /> 17. upload, post, email, transmit or otherwise make available
          any unsolicited or unauthorised advertising, promotional materials,
          "junk mail," "spam," "chain letters," "pyramid schemes," duplicative
          messages or any other form of solicitation. <br />
          <br />
          18. encumber or suffer to exist any lien or security interest on the
          subject matter of this Agreement; or
          <br />
          <br /> 19. make any representation or warranty on behalf of BCCL. You
          agree not to post, distribute, transmit or otherwise make available
          any data, text, message, computer file, or other material that
          infringes and/or violates any right of a third party or any domestic
          or international law, rule, or regulation, including but not limited
          to: <br />
          <br />
          20. infringement of any copyright, trademark, patent, trade secret, or
          other proprietary rights of any third party, including, but not
          limited to, the unauthorized copying of copyrighted material, the
          digitization and distribution of photographs from magazines, books, or
          other copyrighted sources, and the unauthorized transmittal of
          copyrighted software; <br />
          <br />
          21. right of privacy (specifically, you must not distribute another
          person's personal information of any kind without their express
          permission) or publicity; <br />
          <br />
          22. any confidentiality obligation <br />
          <br />
          Unless otherwise permitted, you will: (i) display the Service on your
          Site in the exact form received by you, and not modify or edit any of
          the foregoing without BCCL's prior written consent; (ii) ensure that
          the fundamental meaning of the Service is not changed or distorted;
          (iii) comply with all applicable laws and all limitations and
          restrictions (if any) placed by BCCL on the use, display or
          distribution of any Service and (iv) not archive any of the Service
          for access by users at any future date after the Service has been
          removed from your Web site. You acknowledge that the service must link
          and redirect to the appropriate BCCL's Web page when a user clicks on
          the Service (e.g. a headline). You shall not display the Service in
          such a manner that does not allow for successful linking and
          redirection to, and delivery of, BCCL's Web page, nor may you frame
          any BCCL's Web page. <br />
          <br />
          No Controlling Spam Policy or Unsolicited E-mails
          <br />
          <br />
          You will not use any communication tool or other means available on
          the Site to transmit, directly or indirectly, any unsolicited bulk
          communications (including emails and instant messages). BCCL will not
          sell, lease or rent its e-mail subscriber lists to third parties. You
          may not harvest information about users of BCCL for the purpose of
          sending or to facilitate the sending of unsolicited bulk
          communications. We may terminate your access or use of the Site
          immediately, with or without any notice, and take any other legal
          action if you, or anyone using your access details to the Site,
          violates these terms. We may adopt any technical remedy (including any
          filtering technology or other measures) to prevent unsolicited bulk
          communications from entering, utilizing or remaining within our
          computer or communication networks. Such filtering technology or other
          measures may block, either temporarily or permanently, some e-mail
          sent to you through the BCCL's Web Sites. <br />
          <br />
          Disclaimer of Warranties and Liability <br />
          <br />
          All warranties without limitation, the implied warranties of
          merchantability, fitness for a particular purpose, workmanlike effort,
          title and non-infringement are disclaimed and excluded.
          <br />
          <br /> BCCL and its parent, affiliates and associates shall not be
          liable, at any time for any, direct, indirect, punitive, incidental,
          special, consequential, damages (including, without limitation,
          damages for loss of business projects, damage to your computer system
          or damages for loss of profits, damages for loss of use, data or
          profits, arising out of or in any way connected with the use or
          performance of the BCCL's sites/services, with the delay or inability
          to use the BCCL's sites/services or related services, the provision of
          or failure to provide services, or for any information, software,
          products, services and related graphics obtained through the BCCL
          sites/services, or otherwise arising out of the use of the BCCL
          sites/services) arising in contract, tort or otherwise from the use of
          or inability to use the Site, or any of its contents, or from any act
          or omissions a result of using the Site or any such contents or for
          any failure of performance, error, omission, interruption, deletion,
          defect, delay in operation or transmission, computer virus,
          communications line failure, theft or destruction or unauthorised
          access to, alteration of, or use of information contained on the site,
          shutting down of the whole or part of the Indiatimes Mail Services
          that might result in any loss of information, non retrieval or loss of
          data, or arising out of the inability to use any services.
          <br />
          <br /> Links to Other Sites <br />
          <br />
          All the contents of this Site are only for general information or use.
          They do not constitute advice and should not be relied upon in making
          (or refraining from making) any decision. Any specific advice or
          replies to queries in any part of the Site is/are the personal opinion
          of such experts/consultants/persons and are not subscribed to by this
          Site. The information from or through this site is provided on "AS IS"
          basis, and all warranties and conditions, expressed or implied of any
          kind, regarding any matter pertaining to any goods, service or
          channel, including without Certain links on the Site lead to resources
          located on servers maintained by third parties, these sites of third
          party(s) may contain BCCL's-logo, please understand that it is
          independent from BCCL, over whom BCCL has no control or connection,
          business or otherwise as these sites are external to BCCL. You agree
          and understand that by visiting such sites you are beyond the BCCL's
          website. BCCL, therefore neither endorses nor offers any judgement or
          warranty and accepts no responsibility or liability for the
          authenticity, availability, suitability, reliability, accuracy of the
          information, software, products, services and related graphics
          contained, of any of the goods/services/or for any damage, loss or
          harm, direct or consequential or any violation of local or
          international laws that may be incurred by your visit and/or
          transaction/s on these site(s), as the same is provided on "as is"
          without warranty of any kind. BCCL gives no warranty and makes no
          representation whether expressed or implied, that the information
          contained in this site is error free. BCCL shall not be responsible
          nor liable for any consequential damages arising on account of your
          relying on the contents of the advertisement. Before relying on the
          material, users should independently verify its relevance for their
          purposes, and should obtain any appropriate professional advice.
          <br />
          <br />
          Material Submitted by Users
          <br />
          <br /> Certain elements of the Site will contain material submitted by
          users. BCCL accepts no responsibility for the content, accuracy,
          conformity to applicable laws of such material. You will indemnify and
          hold harmless BCCL against all third party claims, demands and actions
          brought against BCCL which arises from or relates to the material
          submitted by you.
          <br />
          <br /> Advertising Material <br />
          <br />
          Part of the Site contains advertising information or promotion
          material or other material submitted to BCCL by third parties.
          Responsibility for ensuring that material submitted for inclusion on
          Site complies with applicable International and National law is
          exclusively on the party providing the information/material. Your
          correspondence or business dealings with, or participation in
          promotions of, advertisers other than BCCL found on or through the
          Website, including payment and delivery of related goods or services,
          and any other terms, conditions, warranties or representations
          associated with such dealings, are solely between You and such
          advertiser. BCCL will not be responsible or liable for any claim,
          error, omission, inaccuracy in advertising material or any loss or
          damage of any sort incurred as the result of any such dealings or as
          the result of the presence of such non-BCCL advertisers on the
          Website. BCCL reserves the right to omit, suspend or change the
          position of any advertising material submitted for insertion.
          Acceptance of advertisements on the Site will be subject to these
          terms and conditions. <br />
          <br />
          Data Protection <br />
          <br />
          BCCL may send information and offer products and services to you from
          time to time. For further details relating to our policy relating to
          such offer please refer to our privacy statement. Notwithstanding the
          foregoing, BCCL reserves the right to disclose any information in
          response to / that it is required to be shared, disclosed or make made
          available to any governmental, administrative, regulatory or judicial
          authority under any law or regulation applicable to BCCL. Further,
          BCCL can (and you authorize BCCL to) disclose your name, street
          address, city, state, zip code, country, phone number, email, and
          company name to Intellectual Property right's owners, as we in our
          sole discretion believe necessary or appropriate in connection with an
          investigation of fraud, intellectual property infringement, piracy, or
          other unlawful activity. <br />
          <br />
          Relationship
          <br />
          <br /> None of the provisions of the User Agreement shall be deemed to
          constitute a partnership or agency between you and BCCL and you shall
          have no authority to bind BCCL in any manner, whatsoever. <br />
          <br />
          Force Majeure
          <br />
          <br /> BCCL shall have no liability to you for any interruption or
          delay, to access the Site irrespective of the cause.
          <br />
          <br /> Indian Law <br />
          <br />
          The Agreement shall be governed by the Laws of India. The Courts of
          law at Delhi/New Delhi shall have exclusive jurisdiction over any
          disputes arising under this agreement. <br />
          <br />
          Entire Agreement <br />
          <br />
          These Terms of Service constitute the entire agreement between the
          parties with respect to the subject matter hereof and supersedes and
          replaces all prior or contemporaneous understandings or agreements,
          written or oral, regarding such subject matter. <br />
          <br />
          Limited time to bring your claim You and BCCL agree that any cause of
          action arising out of or related to the BCCL's web sites, only, must
          commence within one (1) month after the cause of action accrues
          otherwise, such cause of action will be permanently barred.
          <br />
          <br /> Acceptance of Privacy Policy <br />
          <br />
          By using BCCL's sites and services, you signify your acceptance of
          this Privacy Statement. If you do not agree or are not comfortable
          with any policy described in this Privacy statement, your only remedy
          is to discontinue use of BCCL sites. We reserve the right, to modify
          this Privacy Statement at any time. <br />
          <br />
          General Terms
          <br />
          <br /> Rights and obligations under the Terms which by their nature
          should survive will remain in full effect after termination or
          expiration of the Terms. Any express waiver or failure to exercise
          promptly any right under the Terms will not create a continuing waiver
          or any expectation of non-enforcement. If any provision of the Terms
          is held invalid by any law or regulation of any government, or by any
          court or arbitrator, the parties agree that such provision will be
          replaced with a new provision that accomplishes the original business
          purpose, and the other provisions of the Terms will remain in full
          force and effect.
          <br />
          <br />
          Notice of Copyright Infringement <br />
          <br />
          BCCL is not liable for any infringement of copyright arising out of
          materials posted on or transmitted through the site, or items
          advertised on the site, by end users or any other third parties. In
          the event you have any grievance in relation to any Content uploaded
          on the Site, you may contact us at timesreponse@timesgroup.com or
          write at the following address:
          <br />
          <br /> The Times of India Building
          <br />
          <br /> Dr.DN Road, Fort
          <br />
          <br /> Mumbai – 400001
          <br />
          <br />
          Ph:022-66356635 <br />
          <br />
          We request you to please provide the following information in your
          complaint:- <br />
          <br />
          (a) A physical or electronic signature of a person authorized to act
          on behalf of the copyright owner for the purposes of the complaint
          <br />
          <br /> (b) Identification of the copyrighted work claimed to have been
          infringed. <br />
          <br />
          (c) Identification of the material on our website that is claimed to
          be infringing or to be the subject of infringing activity. <br />
          <br />
          (d) The address, telephone number or e-mail address of the complaining
          party. <br />
          <br />
          (e)A statement that the complaining party has a good-faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent or the law. <br />
          <br />
          (f) A statement, under penalty of perjury, that the information in the
          notice of copyright infringement is accurate, and that the complaining
          party is authorized to act on behalf of the owner of the right that is
          allegedly infringed. <br />
          <br />
          Copyright Notice
          <br />
          <br /> Copyright ©2019 Bennett, Coleman &amp; Company Limited. All
          rights reserved.
          <br />
          <br />
          This disclaimer/terms of service notification is subject to change
          without notice.
        </div>
      </div>
    );
  }
}
export default withRouter(LoginTerms);

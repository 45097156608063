import React from "react";
import "./styles.scss";

const handleContactUsClick = () => {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLSd4bt1hwLIhzN_biHX6SwJnwMMO9hFsFf4EjaTshm4lMxtR5Q/viewform"
  );
};

export const ContactUs = props => {
  return (
    <div className="contactCont">
      <div className="footer_row" onClick={handleContactUsClick}>
        <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Messaging.png" />
        <p>Contact Us</p>
      </div>
      <div
        className="footer_row"
        onClick={() => {
          window.open(props.paper_link || "https://epaper.timesgroup.com");
        }}
      >
        <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/newsicn.png" />
        <p>Read our e-paper</p>
      </div>
    </div>
  );
};

export const PrivacyPolicy = props => {
  return (
    <div className="privacy">
      <div
        className="footer_row"
        onClick={() => {
          window.open("https://wineasy.in/privacy");
        }}
      >
        <p>Privacy Policy</p>
      </div>
      <div
        className="footer_row"
        onClick={() => {
          window.open("https://wineasy.in/quizTerms");
        }}
      >
        <p>Terms &amp; Conditions</p>
      </div>
    </div>
  );
};

const routes = {
  V4_UTILS_SIGNIN: {
    path: "/v4/utils/signinTOIUser",
    method: "post"
  },
  V4_UTILS_SIGNUP: {
    path: "/v4/utils/createTOIUser",
    method: "post"
  },
  V4_UTILS_VERIFY_OTP: {
    path: "/v4/utils/verifyTOIOTP",
    method: "post"
  },
  V4_UTILS_GET_QUIZ: {
    path: "/v4/utils/getQuiz",
    method: "post"
  },
  V4_UTILS_QUIZ_RESULT: {
    path: "/v4/utils/quizResult",
    method: "post"
  },
  V4_UTILS_CHECK_VALID_URL: {
    path: "/v4/utils/checkValidURL",
    method: "post"
  },
  V4_UTILS_SAVE_ANSWER: {
    path: "/v4/utils/saveTOIAnswer",
    method: "post"
  },
  V1_GET_LATEST_APK: {
    path: "/v3/game/web/getTemplateData",
    method: "get"
  },
  V1_SEND_OTP: {
    path: "/v4/utils/sendTOIOtp",
    method: "post"
  },
  V4_UTILS_TERMS_AND_CONDITIONS: {
    path: "/v4/utils/getTOITermsAndCondition",
    method: "post"
  },
  V4_UTILS_GET_LIVE_QUIZ: {
    path: "/v4/utils/fetchLiveCampaign",
    method: "get"
  },
  V4_FETCH_ALL_LIVECAMPAIGNS:{
    path: "/v4/utils/fetchAllLiveCampaign",
    method: "get"
  }

};

export default routes;

import React, { Component } from "react";
import { withRouter } from "react-router";
import { Header } from "../signUp/components";
import "./style.scss";
import { ContactUs, PrivacyPolicy } from "../common/index";
import api from "../../utils/api";
import parse from "html-react-parser";

class SelectLocation extends Component {
  constructor() {
    super();
    this.state = {
      quizData: {},
      liveCampaign: {},
      currentLocation: ""
    };
  }

  async componentDidMount() {
    const campaign_url = localStorage.getItem("campaign_url");
    const action2 = Object.assign({});
    action2.handler = "V4_FETCH_ALL_LIVECAMPAIGNS";
    const response2 = await api(action2);
    if (response2.status === 200) {
      this.setState({
        liveCampaign: response2.data
      });
    }
  }

  selectLocation = state => {
    this.setState({
      currentLocation: state
    });
  };

  findUrl = async () => {
    let campaign_url = localStorage.getItem("campaign_url");
    const action = Object.assign({});
    action.handler = "V4_UTILS_CHECK_VALID_URL";
    action.payload = {
      campaign_url: campaign_url
    };
    const response = await api(action);
    if (response.status === 200) {
      console.log("checkValidURL success");
      switch (response.data.screenName) {
        case "quizScreen":
          this.props.history.push("play");
          break;
        case "rightAnswerWonScreen":
          this.props.history.push("coupons");
          break;
        case "rightAnswerLostScreen":
          this.props.history.push("coupons");
          break;
        case "rightAnswerScreen":
          this.props.history.push("coupons");
          break;
        case "wrongAnswerScreen":
          this.props.history.push("coupons");
          break;
        case "brokenScreen":
          this.props.history.push("wrongUrl");
          break;
        case "expiryScreen":
          this.props.history.push("endquiz");
          break;
      }
    } else if (response.status === 401) {
      console.log("invalid auth");
      this.props.history.push("Signup");
    } else {
      console.log("checkValidURL fails");
      this.props.history.push("endquiz");
    }
  };

  gotToPlay = data => {
    localStorage.setItem("campaign_url", data);
    this.findUrl();
  };

  navigateToHome = () => {
    this.props.history.push("/SelectLocation");
  };

  capitalizeLocation = item => {
    const formattedLocation = item.charAt(0).toUpperCase() + item.substring(1);
    return formattedLocation;
  };

  render() {
    const { currentLocation } = this.state;
    const liveCampaign =
      this.state.liveCampaign && this.state.liveCampaign.campaigns;
    const campaignData = this.state.quizData && this.state.quizData.campaign;
    const paper_link = campaignData && campaignData.paper_link;

    return (
      <div className="playContainer">
        <Header
          navigateToHome={this.navigateToHome}
          selectLocation={this.selectLocation}
        />
        <div style={{ marginBottom: "20px" }} className="firstBanner">
          <h4 style={{ color: "#fff" }} className="welcomeText">
            New Quizzes, New Prizes every day, with Win Easy.
          </h4>
        </div>

        <div className="qstnsCont">
          <h4 className="welcomeText">Welcome to WIN EASY!</h4>
          {(liveCampaign && liveCampaign.length) ||
          (liveCampaign && Object.keys(liveCampaign).length) ? (
            <h6>
              {this.state.currentLocation
                ? "Please select a Brand to Play the Quiz"
                : "Please select your city"}
            </h6>
          ) : (
            <h6>
              There are no live quizzes right now. Please check back later.
            </h6>
          )}

          <div
            style={
              this.state.currentLocation
                ? { display: "none" }
                : { display: "block" }
            }
            className="options"
          >
            <div className="row">
              {liveCampaign &&
                Object.keys(liveCampaign).map(item => {
                  return (
                    <span onClick={this.selectLocation.bind(null, item)}>
                      {this.capitalizeLocation(item)}
                    </span>
                  );
                })}
            </div>
          </div>
          <div
            style={
              this.state.currentLocation
                ? { display: "block" }
                : { display: "none" }
            }
            className="options"
          >
            <div className="row flexRow">
              {liveCampaign &&
                liveCampaign[currentLocation] &&
                liveCampaign[currentLocation].map(item => {
                  return (
                    <span
                      onClick={this.gotToPlay.bind(null, item.campaign_url)}
                    >
                      <img src={item.brand_partner_logo} />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="secondBanner">
          <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra-min.png" />
        </div>
        <ContactUs paper_link={paper_link} />
        <PrivacyPolicy />
      </div>
    );
  }
}
export default withRouter(SelectLocation);

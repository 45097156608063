import React, { Component } from "react";
import { withRouter } from "react-router";
import { Header } from "../signUp/components";
import "./style.scss";
import { ContactUs, PrivacyPolicy } from "../common/index";
import api from "../../utils/api";
import parse from "html-react-parser";

class Play extends Component {
  constructor() {
    super();
    this.state = {
      quizData: {}
    };
  }

  async componentDidMount() {
    const campaign_url = localStorage.getItem("campaign_url");
    const action = Object.assign({});
    action.handler = "V4_UTILS_GET_QUIZ";
    action.payload = {
      campaign_url: campaign_url
    };
    const response = await api(action);
    if (response.status === 200) {
      console.log("quiz success");
      this.setState({
        quizData: response.data
      });
    } else if (response.status === 401) {
      console.log("invalid auth");
      this.props.history.replace("Signup");
    } else {
      console.log("quiz fail");
      this.props.history.replace("/homeScreen");
    }
  }
  getFormattedDate = campaign => {
    const campaignDate = campaign && campaign.date;
    const date = new Date(campaignDate);
    const currentDate = date.getDate();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const formattedDate = currentDate + "/" + currentMonth + "/" + currentYear;
    return formattedDate;
  };

  handleSubmitAnswer = async option => {
    const token = localStorage.getItem("token");
    const question_id =
      this.state.quizData &&
      this.state.quizData.question &&
      this.state.quizData.question.id;
    if (token) {
      const action = Object.assign({});
      action.handler = "V4_UTILS_SAVE_ANSWER";
      action.payload = {
        answer: option,
        question_id: question_id
      };
      const response = await api(action);
      if (response.status === 200) {
        console.log("answer submit success");
        this.props.history.replace("/coupons");
      } else {
        console.log("answer submit fail");
      }
    } else {
      localStorage.setItem(
        "answerOption",
        JSON.stringify({ question_id: question_id, answer: option })
      );
      this.props.history.replace("/signup");
    }
  };

  navigateToHome = () => {
    this.props.history.replace("/SelectLocation");
  };

  render() {
    const campaignData = this.state.quizData && this.state.quizData.campaign;
    const paper_link = campaignData && campaignData.paper_link;
    const adBannerUtil =
      campaignData && campaignData.ad_banner && campaignData.ad_banner[0];

    const rewardsBannerUtil =
      campaignData &&
      campaignData.reward_banner &&
      campaignData.reward_banner[0];

    if (this.state.quizData && this.state.quizData.campaign) {
      const questionUtil = this.state.quizData.question;
      return (
        <div className="playContainer">
          <Header navigateToHome={this.navigateToHome} />
          <div className="firstBanner">
            {adBannerUtil && adBannerUtil.clickable ? (
              <img
                src={adBannerUtil && adBannerUtil.url}
                onClick={() => {
                  window.open(adBannerUtil.clickable_link);
                }}
              />
            ) : (
              <img src={adBannerUtil && adBannerUtil.url} />
            )}
          </div>

          <div className="trophyBanner">
            {rewardsBannerUtil && rewardsBannerUtil.clickable ? (
              <img
                src={rewardsBannerUtil && rewardsBannerUtil.url}
                onClick={() => {
                  window.open(rewardsBannerUtil.clickable_link);
                }}
              />
            ) : (
              <img src={rewardsBannerUtil && rewardsBannerUtil.url} />
            )}

            <div>
              <p className="orange">Today&apos;s Prizes</p>
              <p>
                {rewardsBannerUtil &&
                  rewardsBannerUtil.reward_detail &&
                  parse(rewardsBannerUtil.reward_detail)}
              </p>
            </div>
          </div>
          <div className="qstnsCont">
            <div className="date_answers">
              {this.getFormattedDate(this.state.quizData.campaign)}
            </div>
            <p>{questionUtil && questionUtil.question}</p>
            <div className="options">
              <div className="row">
                <span
                  onClick={() => {
                    this.handleSubmitAnswer(1);
                  }}
                >
                  {questionUtil && questionUtil.question_option_1}
                </span>
                <span
                  onClick={() => {
                    this.handleSubmitAnswer(2);
                  }}
                >
                  {questionUtil && questionUtil.question_option_2}
                </span>
                <span
                  onClick={() => {
                    this.handleSubmitAnswer(3);
                  }}
                >
                  {questionUtil && questionUtil.question_option_3}
                </span>
                {/* <span
                  onClick={() => {
                    this.handleSubmitAnswer(4);
                  }}
                >
                  {questionUtil && questionUtil.question_option_4}
                </span> */}
              </div>
            </div>
          </div>
          <div className="secondBanner">
            <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra-min.png" />
          </div>
          <ContactUs paper_link={paper_link} />
          <PrivacyPolicy />
        </div>
      );
    }
    return (
      <div className="playContainer">
        <Header navigateToHome={this.navigateToHome} />
        <div className="firstBanner">
          <img
            src={
              campaignData &&
              campaignData.ad_banner &&
              campaignData.ad_banner[0] &&
              campaignData.ad_banner[0].url
            }
          />
        </div>

        <div className="trophyBanner">
          <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/WinEasy.png" />
          <div>
            <p className="orange">Today&apos;s Prizes</p>
            <p>
              {campaignData &&
                campaignData.reward_banner &&
                campaignData.reward_banner[0] &&
                campaignData.reward_banner[0].reward_detail &&
                parse(campaignData.reward_banner[0].reward_detail)}
            </p>
          </div>
        </div>
        <div className="secondBanner">
          <img
            src={
              campaignData &&
              campaignData.reward_banner &&
              campaignData.reward_banner[0] &&
              campaignData.reward_banner[0].url
            }
          />
        </div>
        <ContactUs />
        <PrivacyPolicy />
      </div>
    );
  }
}
export default withRouter(Play);

import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "../shells/Login";
import Signup from "../shells/signUp";
import Play from "../shells/Play";
import Results from "../shells/Results";
import EndQuiz from "../shells/EndQuiz";
import splashScreen from "../shells/SplashScreen";
import WrongUrl from "../shells/EndQuiz/wrongUrl";
import HomeScreen from "../shells/EndQuiz/homeScreen";
import CouponTerms from "../shells/Results/couponTerms";
import LoginTerms from "../shells/common/loginTerms";
import QuizTerms from "../shells/common/quizTerms";
import PrivacyPolicy from "../shells/common/privacyPolicy";
import SelectLocation from "../shells/Play/components";
import Coupons from "../shells/Results/results";

function Root() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={splashScreen} />
        <Route path="/endquiz" component={EndQuiz} />
        <Route path="/Signup" component={Signup} />
        <Route path="/login" component={Login} />
        <Route path="/Play" component={Play} />
        <Route path="/selectLocation" component={SelectLocation} />
        <Route path="/results" component={Results}/>
        <Route path="/coupons" component={Coupons} />
        <Route path="/wrongUrl" component={WrongUrl} />
        <Route path="/homeScreen" component={HomeScreen} />
        <Route path="/couponTerms" component={CouponTerms} />
        <Route path="/loginTerms" component={LoginTerms} />
        <Route path="/quizTerms" component={QuizTerms} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="*" component={splashScreen} />
      </Switch>
    </Router>
  );
}

export default Root;

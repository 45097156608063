import React, { Component } from "react";
import './login.scss';
import { withRouter } from "react-router";
class Login extends Component{
    render(){
        return(
            <p>Login component</p>
        )
    }
}
export default withRouter((Login));
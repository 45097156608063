import React, { Component } from "react";
import { withRouter } from "react-router";

class QuizTerms extends Component {
  render() {
    return (
      <div className="root_loginTerms">
        <div className="hdr_loginTerms">TERMS AND CONDITIONS</div>
        <div className="body_loginTerms">
          Bennett, Coleman &amp; Co. Limited (BCCL) (hereinafter referred to as
          BCCL) is a company within the provisions of the Companies Act, 2013,
          having its Registered Office at The Times of India Building, Dr. D.N.
          Road, Mumbai 400 001. By scanning the QR code as available at BCCL’s
          publication and by participating in &#39;WinEasy’ (hereinafter
          referred to as the &#39;Contest&#39;), you accept and agree that you
          are of the minimum age of 18 years or participating in the Contest
          with the due approval / consent of your guardian and accept and agree
          to the following terms and conditions: - (1) The Contest is organized
          managed and conducted by the BCCL. (2) This Contest is subject to
          applicable laws, BCCL&#39;s policies and other conditions. (3) The
          Contest will be open and live from 29 Januar,2019 and may remain open
          at the discretion of BCCL. (4) This Contest is open only for the
          residents of locations across India (5) Participants will scan a QR
          code and answer the quiz on a microsite. Winners would be identified
          at random from the eligible participants who have answered the quiz
          right and would be notified via SMS (6) All documents / posts / texts
          received shall remain the property of BCCL. (7) Decision of the
          Management of BCCL is final and no correspondence or any other claims
          whatsoever, in this regard will be entertained. (8) The Prize is
          subject to the laws of India and deduction of taxes, levies and other
          statutory payments shall be as applicable and borne solely by the
          winner only. BCCL will not be responsible to pay any applicable tax or
          duty or levy or fee. (9) BCCL does not give any warranty or guarantee
          concerning the quality, suitability or merchantability of any prize
          and BCCL or its management, directors, officers, agents,
          representatives shall not be responsible for or liable (including but
          not limited to the Product and Service Liabilities) for deficiency
          and/ or defect of any product/service given as the prize or any kind
          of consequential damages/ loss, in any manner whatsoever. BCCL shall
          not be responsible for any realization of Gift vouchers or prize. Any
          prize or gift shall be handed over to winners only after payment of
          any applicable TDS and all winners must possess a valid PAN card. (10)
          Friends and families of BCCL employees shall not be eligible to
          participate in this Contest. (11) All right, title and interest,
          including but not limited to the Intellectual Property Rights, in the
          promotional material(s) and / or images, posts, texts, videos or any
          such material created by BCCL or submitted by the participants shall
          vest solely and exclusively with BCCL at all times. (12) BCCL reserves
          the right to change, alter or cancel this Contest in part or full, or
          change any or all of the terms and conditions that are applicable,
          without giving prior intimation/notice of any kind and will not be
          liable for any consequential losses/damages. Decision of BCCL in this
          regard and in general with respect to this Contest and the Terms &amp;
          Conditions thereto shall be final, binding and non-contestable. (13)
          Nothing contained herein amounts to a commitment or representation by
          BCCL to conduct further or other schemes. (14) All disputes shall be
          subject to the jurisdiction of the Courts at Mumbai only. (15) Images/
          Pictures/ Photographs displayed in the promotional campaign for the
          Contest are for reference only; actual gifts or prize may appear
          different. (16) Gifts are subject to the laws prevailing in the
          country, including regulations as may be applicable to the winner. No
          correspondence in this regard will be entertained under any
          circumstances. (17) Participants’ entering the Contest shall be deemed
          as his/her acceptance of the terms and conditions. (18) Gifts or prize
          cannot be exchanged or redeemed for cash; no cash claim can be made in
          lieu of the gifts or prize. (19) Winners shall not conceal or
          misrepresent any information during the scrutiny or otherwise, failing
          which the participant shall be disqualified for participating in the
          Contest. (20) BCCL reserves the right to withdraw &amp;/or alter any
          of the terms &amp; conditions of this Contest at any time without
          prior notice. (21) Interpretation of Rules and Regulations and terms
          &amp; conditions for any legal issue will be considered only in
          English. (22) Terms and conditions as mentioned in this document shall
          be read together harmoniously with Terms of Use and Privacy Policy as
          available at www.wineasy.in. (23) Prizes and vouchers may be provided
          by the sponsoring entity / partner entity and hence, the same will be
          additionally subject to terms and conditions of such sponsoring /
          partnering entity. Any dispute or grievances in relation to any gift
          voucher or prize shall be directly addressed to such sponsoring /
          partnering entity. The terms and conditions of such sponsoring /
          partnering entity shall be available on [mention URL of such
          sponsoring / partner entity]
        </div>
      </div>
    );
  }
}
export default withRouter(QuizTerms);

import React, { Component } from "react";
import { withRouter } from "react-router";
import api from "../../utils/api";
import parse from "html-react-parser";

class CouponTerms extends Component {
  constructor() {
    super();
    this.state = {
      termsData: ""
    };
  }
  async componentDidMount() {
    if (localStorage.getItem("token")) {
      const campaign_url = localStorage.getItem("campaign_url");
      const action = Object.assign({});
      action.handler = "V4_UTILS_TERMS_AND_CONDITIONS";
      action.payload = {
        campaign_url: campaign_url
      };
      const response = await api(action);
      if (response.status === 200) {
        if (response.data) {
          this.setState({
            termsData: response.data
          });
        }
      } else {
        this.props.history.replace("/homeScreen");
      }
    } else {
      this.props.history.replace("/homeScreen");
    }
  }

  closeCouponTermsTab = () => {
    window.close();
  };

  render() {
    if (this.state.termsData && this.state.termsData.terms_and_conditions) {
      return (
        <div className="root_termsScreen">
          <div className="body_termsScreem">
            <div className="lbl_couponTerms">Voucher Terms</div>
            <div className="btn_closeTerms" onClick={this.closeCouponTermsTab}>
              Close
            </div>
          </div>
          {parse(this.state.termsData.terms_and_conditions)}
        </div>
      );
    }
    return <div className="root_termsScreen">Something went wrong</div>;
  }
}
export default withRouter(CouponTerms);

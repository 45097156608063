import React from "react";
import "./signup.scss";
import { tsPropertySignature } from "@babel/types";
import wineasylogo from "../../assets/wineasylogo.png";
export const VerifyLogin = props => {
  return (
    <div className="verifyArea addPAdding">
      <img
        className="verfyIcon"
        src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/VerifytoWin.png"
      />
      <h4>Step 2 : Verify profile to win prizes!</h4>
      <p>
        OTP sent to your number. Please enter the OTP to verify your account &
        win prizes!
      </p>
      <div className="otpVerify">
        <input
          style={
            props.otpError
              ? { border: "1px solid red" }
              : { boxSizing: "border-box" }
          }
          value={props.otp}
          onChange={props.otpHandler}
          type="text"
          placeholder="OTP"
        />
        {props.otpError ? (
          <p className="verifYError">Please enter a valid otp</p>
        ) : (
          ""
        )}
      </div>

      <div className="submitBtn">
        <button id="verifyLogin">Verify</button>
      </div>
    </div>
  );
};
export const Verify = props => {
  return (
    <div className="verifyArea addPAdding">
      <img
        className="verfyIcon"
        src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/VerifytoWin.png"
      />
      <h4>Step 2 : Verify profile to win prizes!</h4>
      <p>
        OTP sent to your number. Please enter the OTP to verify your account
        &amp; win prizes!
      </p>
      <div className="otpVerify">
        <input
          style={
            props.otpError
              ? { border: "1px solid red" }
              : { boxSizing: "border-box" }
          }
          value={props.otp}
          onChange={props.otpHandler}
          type="text"
          placeholder="OTP"
        />
        {props.otpError ? <p className="verifYError">{props.otpError}</p> : ""}
      </div>

      <div className="submitBtn">
        <button id="verify">Verify</button>
      </div>
    </div>
  );
};

export const Login = props => {
  if (props.signupOtp) {
    return (
      <VerifyLogin
        otpError={props.otpError}
        otp={props.otp}
        otpHandler={props.otpHandler}
      />
    );
  }
  return (
    <div className="verifyArea addPAdding">
      <img
        className="verfyIcon"
        src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/SecurePhone.png"
      />
      <h4>Step 1 : Enter your Mobile No</h4>
      <p>Share your details so that we can send you prizes!</p>
      <div className="loginNumber">
        <input
          style={
            props.loginnumberError
              ? { border: "1px solid red" }
              : { boxSizing: "border-box" }
          }
          maxLength="10"
          onChange={props.loginnumberHandler}
          value={props.loginnumber}
          type="text"
          placeholder="Phone Number"
        />
        {props.loginnumberError ? (
          <p className="error">Please enter a valid phone number</p>
        ) : (
          ""
        )}
      </div>

      <div className="submitBtn">
        <button id="login">Login</button>
      </div>
    </div>
  );
};

export const Register = props => {
  const age = ["below 20", "20-29", "30-39", "40-49", "50+"];
  return (
    <div className="verifyArea register">
      <h4>Step 1 : Provide details</h4>
      <p>Share your details so that we can send you prizes!</p>

      <div className="registerForm">
        <div className="nameCont">
          <input
            style={
              props.name1Error
                ? { border: "1px solid red" }
                : { boxSizing: "border-box" }
            }
            value={props.name1}
            onChange={props.name1Handler}
            id="name1"
            type="text"
            placeholder="Name"
          />
          {props.name1Error ? (
            <p id="nameError1">Please enter a valid name</p>
          ) : (
            ""
          )}
        </div>

        <div className="ageandSex">
          <select
            required
            className={props.ageError ? "genderErrorBox" : ""}
            onChange={props.ageHanlder}
          >
            <option value="" disabled selected hidden>
              Age
            </option>
            {age.map((item, index) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          {props.ageError ? (
            <div className="ageError">Select your age</div>
          ) : (
            <div />
          )}

          <select
            className={props.genderError ? "genderErrorBox" : ""}
            onChange={props.genderHandler}
          >
            <option value="" disabled selected hidden>
              Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {props.genderError ? (
            <div className="ageSex">Select your gender</div>
          ) : (
            <div />
          )}
        </div>
        <div id="location" className="location">
          <input
            style={
              props.locationError
                ? { border: "1px solid red" }
                : { boxSizing: "border-box" }
            }
            onChange={props.locationHandler}
            placeholder="Location"
            type="text"
            value={props.location}
          />
          {props.locationError ? (
            <p id="locationError">Please enter your location</p>
          ) : (
            ""
          )}
        </div>
        <div id="phoneNumber1" className="location">
          <input
            style={
              props.phoneNumber1Error
                ? { border: "1px solid red" }
                : { boxSizing: "border-box" }
            }
            maxLength="10"
            value={props.phoneNumber1}
            onChange={props.phonenumberHandler}
            placeholder="Phone number"
            type="text"
          />
          {props.phoneNumber1Error ? (
            <p id="phoneError1">Please enter a valid Phone number</p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="para_terms" onClick={handleLoginTerms}>
        By clicking Sign Up, you agree to our{" "}
        <span className="link_terms">Terms &amp; Conditions</span>
      </div>
      <div className="submitBtn">
        <button id="signup">Sign Up</button>
      </div>
    </div>
  );
};

export const Header = props => {
  return (
    <div
      style={{ paddingBottom: "5px" }}
      className="verifyHeader"
      onClick={() => {
        if (props.selectLocation) {
          props.selectLocation("");
        }
        props.navigateToHome();
      }}
    >
      <img className="winIcon" src={wineasylogo} />
      {/* <p>Presented By Times Group &amp; Nostragamus</p> */}
      {/* <div className="user">
        <button>Log in</button>
      </div> */}
    </div>
  );
};

const handleLoginTerms = () => {
  window.open("https://wineasy.in/loginTerms");
};

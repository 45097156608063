import axios from "axios";
import routes from "./apiRoutes";

export default async function api(action) {
  const token =
    localStorage.getItem("token") ||
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjotMSwiaWF0IjoxNTc2ODUwMjcwLCJleHAiOjE4OTIyMTAyNzB9.UcGIoQtbBRDnAM9d_IYQG6FkWN89ydRjJrESreng17c";
  const baseUrl =
    process.env.REACT_APP_SC_ENV === "production"
      ? "https://nostratoi.sportscafe.in"
      : //: "http://nostrapi.toi-v4.kstage.sportscafe.in";
        "https://api-stage02.sportscafe.in";

  const form = action.containsFormData
    ? { "Content-Type": "multipart/form-data" }
    : {};
  const headers = Object.assign({}, form, { Authorization: token });

  try {
    const response = await axios({
      method: routes[action.handler].method,
      url: baseUrl + routes[action.handler].path,
      data: action.payload || null,
      params:
        action.handler === "V1_GET_LATEST_APK"
          ? { tid: "toi" }
          : action.query || null,
      headers
      // withCredentials: true
      // credentials: 'same-origin',
    });
    console.log(response);
    return response;
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 401) {
        console.log(error);
        return error.response;
        //yield put({ type: "USER_LOGOUT" });
      } else {
        console.log(error);
        //yield put({ type: "UNCAUGHT_ERR", info: "Error", err: "error" });
        return error.response;
      }
    }

    console.log(error);
    return { data: { data: null }, error };
  }
}

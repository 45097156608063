import React from "react";
import "./results.scss";
import { Header } from "../signUp/components";
import parse from "html-react-parser";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";
import fbIcon from "../../assets/fb.png";

const getValidityDate = datetime => {
  const date = new Date(datetime).getDate();
  const month = new Date(datetime).getMonth();
  const year = new Date(datetime).getFullYear();
  let formattedMonth = month + 1;
  formattedMonth = formattedMonth.toString();
  const formattedDate = date + "-" + formattedMonth + "-" + year;
  return formattedDate;
};

export const ResultsWon = props => {
  const campaignData = props.resultsData && props.resultsData.campaign;
  const adBannerUtil =
    campaignData && campaignData.ad_banner && campaignData.ad_banner[0];
  const rewardBannerUtil =
    campaignData && campaignData.reward_banner && campaignData.reward_banner[0];
  const quizResultsUtil =
    props.resultsData &&
    props.resultsData.quizResult &&
    props.resultsData.quizResult.data;
  return (
    <div className="root">
      <div>
        <Header navigateToHome={props.navigateToHome} />
      </div>
      <div className="hdr_image">
        {adBannerUtil && adBannerUtil.clickable ? (
          <img
            src={adBannerUtil && adBannerUtil.url}
            onClick={() => {
              window.open(adBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={adBannerUtil && adBannerUtil.url} />
        )}
      </div>
      <div className="trophyBanner">
        {rewardBannerUtil && rewardBannerUtil.clickable ? (
          <img
            src={rewardBannerUtil && rewardBannerUtil.url}
            onClick={() => {
              window.open(rewardBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={rewardBannerUtil && rewardBannerUtil.url} />
        )}

        <div>
          <p className="orange">Today&apos;s Prizes</p>
          <p>
            {rewardBannerUtil &&
              rewardBannerUtil.reward_detail &&
              parse(rewardBannerUtil.reward_detail)}
          </p>
        </div>
      </div>
      <div className="root_body">
        <div className="date_answers">{getFormattedDate(props.date)}</div>
        <img
          className="img_won"
          src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Gift.png"
        />
        <div className="txt_winner">
          Won a {quizResultsUtil.reward_name} voucher worth Rs
          {quizResultsUtil.reward_value}!
        </div>
        <div className="root_coupon">
          <div>
            <div className="txt_coupon">
              Coupon Code - {quizResultsUtil && quizResultsUtil.coupon_code}
            </div>
            <div className="txt_validity">
              Validity -{" "}
              {quizResultsUtil &&
                quizResultsUtil.validity &&
                getValidityDate(quizResultsUtil.validity)}
            </div>
          </div>
          <div className="root_terms" onClick={props.navigateToTerms}>
            Voucher Terms
          </div>
        </div>
        <div className="btn_moreQuizzes" onClick={props.navigateToHome}>
          Play more Quizzes
        </div>
      </div>
      <SharePanel campaign_url={props.campaign_url} />
      <div className="root_download" onClick={props.handleAPKDownload}>
        <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra_Download-min.png" />
      </div>

      {/* <div className="footer_playOnlyOnce">
        You can play only one WinEASY quiz per day. Come back tomorrow for the
        next quiz!
      </div> */}
    </div>
  );
};

export const DidNotWin = props => {
  const campaignData = props.resultsData && props.resultsData.campaign;
  const adBannerUtil =
    campaignData && campaignData.ad_banner && campaignData.ad_banner[0];
  const rewardBannerUtil =
    campaignData && campaignData.reward_banner && campaignData.reward_banner[0];
  return (
    <div className="root">
      <div>
        <Header navigateToHome={props.navigateToHome} />
      </div>
      <div className="hdr_image">
        {adBannerUtil && adBannerUtil.clickable ? (
          <img
            src={adBannerUtil && adBannerUtil.url}
            onClick={() => {
              window.open(adBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={adBannerUtil && adBannerUtil.url} />
        )}
      </div>
      <div className="trophyBanner">
        {rewardBannerUtil && rewardBannerUtil.clickable ? (
          <img
            src={rewardBannerUtil && rewardBannerUtil.url}
            onClick={() => {
              window.open(rewardBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={rewardBannerUtil && rewardBannerUtil.url} />
        )}

        <div>
          <p className="orange">Today&apos;s Prizes</p>
          <p>
            {campaignData &&
              campaignData.reward_banner &&
              campaignData.reward_banner[0] &&
              campaignData.reward_banner[0].reward_detail &&
              parse(campaignData.reward_banner[0].reward_detail)}
          </p>
        </div>
      </div>
      <div className="root_body">
        <div className="row_didnotwin">
          <img
            className="img_didnotwin"
            src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/DidNotWin.png"
          />
          <div>
            <div className="txt_didnotwin">
              Bad Luck! You were just a step away!
            </div>
            <div className="subtxt_didnotwin">
              Judging by your answer, a win is just a step away. Come back
              tomorrow to play again!
            </div>
          </div>
        </div>
        <div className="btn_moreQuizzes" onClick={props.navigateToHome}>
          Play more Quizzes
        </div>
      </div>
      <SharePanel campaign_url={props.campaign_url} />
      <div className="root_download" onClick={props.handleAPKDownload}>
        <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra_Download-min.png" />
      </div>
      {/* <div className="footer_playOnlyOnce">
        You can play only one WinEASY quiz per day. Come back tomorrow for the
        next quiz!
      </div> */}
    </div>
  );
};

export const AwaitingWinnersCorrectAnswer = props => {
  const campaignData = props.resultsData && props.resultsData.campaign;
  const adBannerUtil =
    campaignData && campaignData.ad_banner && campaignData.ad_banner[0];
  const rewardBannerUtil =
    campaignData && campaignData.reward_banner && campaignData.reward_banner[0];
  return (
    <div className="root">
      <div>
        <Header navigateToHome={props.navigateToHome} />
      </div>
      <div className="hdr_image">
        {adBannerUtil && adBannerUtil.clickable ? (
          <img
            src={adBannerUtil && adBannerUtil.url}
            onClick={() => {
              window.open(adBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={adBannerUtil && adBannerUtil.url} />
        )}
      </div>
      <div className="trophyBanner">
        {rewardBannerUtil && rewardBannerUtil.clickable ? (
          <img
            src={rewardBannerUtil && rewardBannerUtil.url}
            onClick={() => {
              window.open(rewardBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={rewardBannerUtil && rewardBannerUtil.url} />
        )}

        <div>
          <p className="orange">Today&apos;s Prizes</p>
          <p>
            {campaignData &&
              campaignData.reward_banner &&
              campaignData.reward_banner[0] &&
              campaignData.reward_banner[0].reward_detail &&
              parse(campaignData.reward_banner[0].reward_detail)}
          </p>
        </div>
      </div>
      <div className="root_body">
        <div className="date_answers">{getFormattedDate(props.date)}</div>
        <div className="root_row">
          <img
            className="img_wrong"
            src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/GreenT.png"
          />
          <div className="txt_correct">YOUR ANSWER WAS CORRECT</div>
        </div>

        <div className="subtxt_correct">More you Play, More you Win.</div>

        <div className="btn_moreQuizzes" onClick={props.navigateToHome}>
          Play more Quizzes
        </div>
      </div>
      <SharePanel campaign_url={props.campaign_url} />
      <div className="root_download" onClick={props.handleAPKDownload}>
        <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra_Download-min.png" />
      </div>
      <div className="footer_playOnlyOnce">
        Winners will be selected randomly, and contacted via SMS in the next few
        hours!
      </div>
    </div>
  );
};

export const AwaitingWinnersWrongAnswer = props => {
  const campaignData = props.resultsData && props.resultsData.campaign;
  const adBannerUtil =
    campaignData && campaignData.ad_banner && campaignData.ad_banner[0];
  const rewardBannerUtil =
    campaignData && campaignData.reward_banner && campaignData.reward_banner[0];
  return (
    <div className="root">
      <div>
        <Header navigateToHome={props.navigateToHome} />
      </div>
      <div className="hdr_image">
        {adBannerUtil && adBannerUtil.clickable ? (
          <img
            src={adBannerUtil && adBannerUtil.url}
            onClick={() => {
              window.open(adBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={adBannerUtil && adBannerUtil.url} />
        )}
      </div>

      <div className="trophyBanner">
        {rewardBannerUtil && rewardBannerUtil.clickable ? (
          <img
            src={rewardBannerUtil && rewardBannerUtil.url}
            onClick={() => {
              window.open(rewardBannerUtil.clickable_link);
            }}
          />
        ) : (
          <img src={rewardBannerUtil && rewardBannerUtil.url} />
        )}

        <div>
          <p className="orange">Today&apos;s Prizes</p>
          <p>
            {campaignData &&
              campaignData.reward_banner &&
              campaignData.reward_banner[0] &&
              campaignData.reward_banner[0].reward_detail &&
              parse(campaignData.reward_banner[0].reward_detail)}
          </p>
        </div>
      </div>

      <div className="root_body">
        <div className="date_answers">{getFormattedDate(props.date)}</div>
        <div className="root_row">
          <img
            className="img_wrong"
            src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/CrossRED.png"
          />
          <div className="hdr_wrongAnswer">YOUR ANSWER WAS INCORRECT</div>
        </div>

        <div className="subhdr_wrongAnswer">
          Hard Luck Buddy! But don’t worry. Give it another try.
        </div>

        <div className="btn_moreQuizzes" onClick={props.navigateToHome}>
          Play more Quizzes
        </div>
      </div>
      <SharePanel campaign_url={props.campaign_url} />
      <div className="root_download" onClick={props.handleAPKDownload}>
        <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra_Download-min.png" />
      </div>
      <div className="footer_playOnlyOnce">
        You can play one WinEASY quiz per day.
      </div>
    </div>
  );
};

const getFormattedDate = item => {
  const date = new Date(item);
  const currentDate = date.getDate();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const formattedDate = currentDate + "/" + currentMonth + "/" + currentYear;
  return formattedDate;
};

export const SharePanel = props => {
  return (
    <div>
      <div className="txt_shareWithFrnds">
        Share this Quiz with Your Friends
      </div>
      <div className="root_sharePanel">
        <WhatsappShareButton
          title="Try this exciting quiz from Win Easy, that I just played. Answer simple questions and it could be raining vouchers!"
          url={props.campaign_url}
          separator=" "
        >
          <WhatsappIcon size={36} round />
        </WhatsappShareButton>
        <FacebookShareButton
          quote="Try this exciting quiz from Win Easy, that I just played. Answer simple questions and it could be raining vouchers!"
          url={props.campaign_url}
          separator=" "
        >
          <img src={fbIcon} style={{ height: 35, width: 35 }} />
        </FacebookShareButton>
        <TwitterShareButton
          title="Try this exciting quiz from Win Easy, that I just played. Answer simple questions and it could be raining vouchers!"
          url={props.campaign_url}
          separator=" "
        >
          <TwitterIcon size={36} round />
        </TwitterShareButton>
        <EmailShareButton
          subject="WinEasy"
          body="Try this exciting quiz from Win Easy, that I just played. Answer simple questions and it could be raining vouchers!"
          url={props.campaign_url}
          separator=" "
        >
          <EmailIcon size={36} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

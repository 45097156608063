import React, { Component } from "react";
import "./signup.scss";
import { withRouter } from "react-router";
import { ContactUs } from "../common/index";
import { Verify, Login, Register, Header } from "./components";
import api from "../../utils/api";
class Signup extends Component {
  constructor() {
    super();
    this.state = {
      currentState: false,
      active: "signup",
      btnState: "signup",
      name1: "",
      name1Error: "",
      phoneNumber1: "",
      phoneNumber1Error: "",
      sex: "",
      genderError: "",
      location: "",
      locationError: "",
      age: "",
      ageError: "",
      signupOtp: false,
      otp: "",
      otpError: "",
      loginnumber: "",
      loginnumberError: ""
    };
  }

  checkValidUrl = async payload => {
    const action = Object.assign({});
    action.handler = "V4_UTILS_CHECK_VALID_URL";
    action.payload = payload;
    const response = await api(action);
    if (response.status === 200) {
      console.log("checkValidURL success");
      switch (response.data.screenName) {
        case "quizScreen":
          this.props.history.replace("play");
          break;
        case "rightAnswerWonScreen":
          this.props.history.replace("coupons");
          break;
        case "rightAnswerLostScreen":
          this.props.history.replace("coupons");
          break;
        case "rightAnswerScreen":
          this.props.history.replace("coupons");
          break;
        case "wrongAnswerScreen":
          this.props.history.replace("coupons");
          break;
        case "brokenScreen":
          this.props.history.replace("wrongUrl");
          break;
        case "expiryScreen":
          this.props.history.replace("endquiz");
          break;
      }
    } else {
      console.log("checkValidURL fails");
      this.props.history.replace("endquiz");
    }
  };

  navigateToHome = () => {
    this.props.history.replace("/SelectLocation");
  };

  // handleVerifyOTP = async payload => {
  //   const action = Object.assign({});
  //   action.handler = "V4_UTILS_VERIFY_OTP";
  //   action.payload = payload;
  //   const response = await api(action);
  //   if (response.status === 200) {
  //     const { token } = response.data;
  //     localStorage.setItem("token", token);
  //     // this.checkValidUrl({
  //     //   campaign_url:
  //     //     "http://localhost:4020/12-22-2019-Test1-ZOMATO-Bangalore-sports-1"
  //     // });
  //     console.log("otp success");

  //     this.handleSubmitAnswer();
  //   } else {
  //     console.log("otp fail");
  //   }
  // };

  sendOtp = async payload => {
    const action = Object.assign({});
    action.handler = "V1_SEND_OTP";
    action.payload = payload;
    const response = await api(action);
    if (response.status === 200) {
      console.log("signup success");
      this.setState({
        signupOtp: true
      });
    }
  };

  handleSubmitAnswer = async () => {
    const answerOption = localStorage.getItem("answerOption");
    if (answerOption) {
      const action = Object.assign({});
      action.handler = "V4_UTILS_SAVE_ANSWER";
      action.payload = JSON.parse(answerOption);
      const response = await api(action);
      if (response.status === 200) {
        console.log("answer submit success");
        this.props.history.replace("/coupons");
      } else {
        console.log("answer submit fail");
      }
    }
  };

  handleSignUpSubmit = async payload => {
    const action = Object.assign({});
    action.handler = "V4_UTILS_SIGNUP";
    action.payload = payload;
    const response = await api(action);
    if (response.status === 200) {
      console.log("signup success");
      localStorage.setItem("token", response.data.token);
      if (localStorage.getItem("unauthResults")) {
        localStorage.removeItem("unauthResults");
        this.props.history.replace("/coupons");
      } else {
        this.handleSubmitAnswer();
      }
    } else {
      console.log("signup fail");
      this.setState({
        otpError: "Phone Number already exists! Please use Login option."
      });
    }
  };

  handleLoginSubmit = async payload => {
    const action = Object.assign({});
    action.handler = "V4_UTILS_SIGNIN";
    action.payload = payload;
    const response = await api(action);
    if (response.status === 200) {
      console.log("login success");
      localStorage.setItem("token", response.data.token);
      if (localStorage.getItem("unauthResults")) {
        localStorage.removeItem("unauthResults");
        this.props.history.replace("/coupons");
      } else {
        this.handleSubmitAnswer();
      }
    } else {
      this.setState({
        otpError: "Incorrect OTP!"
      });
    }
  };

  handleValidation = e => {
    if (e.target.id === "signUpli") {
      if (this.state.signupOtp) {
        this.setState(
          {
            signupOtp: false
          },
          () => {
            this.setState({
              currentState: false,
              btnState: "signup"
            });
          }
        );
      } else {
        this.setState({
          currentState: false,
          btnState: "signup"
        });
      }
    }
    if (e.target.id === "loginli") {
      if (this.state.signupOtp) {
        this.setState(
          {
            signupOtp: false
          },
          () => {
            this.setState({
              currentState: true,
              btnState: "login"
            });
          }
        );
      }

      this.setState({
        currentState: true,
        btnState: "login"
      });
    }

    if (e.target.id === "signup") {
      let {
        name1,
        name1Error,
        phoneNumber1,
        phoneNumber1Error,
        sex,
        genderError,
        age,
        ageError,
        location,
        locationError
      } = this.state;

      if (!name1) {
        this.setState({
          name1Error: "Please enter a valid name"
        });
      } else if (!phoneNumber1 || phoneNumber1.length < 10) {
        this.setState({
          phoneNumber1Error: "Please enter a valid number"
        });
      } else if (!location) {
        this.setState({
          locationError: "Please enter your location"
        });
      } else if (!age) {
        this.setState({
          ageError: "Please select your age"
        });
      } else if (!sex) {
        this.setState({
          genderError: "Please select your gender"
        });
      } else {
        this.sendOtp({
          mobile_number: Number.parseInt(this.state.phoneNumber1, 10)
        });
      }
    }

    if (e.target.id === "verify") {
      if (!this.state.otp || this.state.otp.length < 4) {
        this.setState({
          otpError: "Please enter a valid otp"
        });
      } else {
        this.handleSignUpSubmit({
          name: this.state.name1,
          age: this.state.age,
          sex: this.state.sex,
          location: this.state.location,
          mobile_number: this.state.phoneNumber1,
          otp: this.state.otp
        });
      }
    }

    if (e.target.id === "verifyLogin") {
      if (!this.state.otp || this.state.otp.length < 4) {
        this.setState({
          otpError: "Please enter a valid otp"
        });
      } else {
        this.handleLoginSubmit({
          mobile_number: this.state.loginnumber,
          otp: this.state.otp
        });
      }
    }

    if (e.target.id === "login") {
      if (!this.state.loginnumber || this.state.loginnumber.length < 10) {
        this.setState({
          loginnumberError: "Please enter a valid number"
        });
      } else {
        this.sendOtp({
          mobile_number: Number.parseInt(this.state.loginnumber, 10)
        });
      }
    }
  };

  genderHandler = e => {
    e.stopPropagation();
    if (this.state.genderError) {
      this.setState({
        sex: e.target.value,
        genderError: ""
      });
    } else {
      this.setState({
        sex: e.target.value
      });
    }
  };

  phonenumberHandler = e => {
    e.stopPropagation();
    if (isNaN(e.target.value) === false) {
      this.setState(
        {
          phoneNumber1: e.target.value
        },
        () => {
          console.log(this.state.name1);
        }
      );
    }

    if (this.state.phoneNumber1Error) {
      this.setState({
        phoneNumber1Error: ""
      });
    }
  };
  name1Handler = e => {
    e.stopPropagation();
    if (this.state.name1Error) {
      this.setState({
        name1Error: ""
      });
    }
    let nameValueHolder = e.target.value;
    nameValueHolder = nameValueHolder.replace(/[^A-Za-z]/gi, "");
    this.setState({
      name1: nameValueHolder
    });
  };
  locationHandler = e => {
    e.stopPropagation();
    if (this.state.locationError) {
      this.setState({
        locationError: ""
      });
    }
    let locationPlaceholder = e.target.value;
    locationPlaceholder = locationPlaceholder.replace(/[^A-Za-z]/gi, "");
    this.setState({
      location: locationPlaceholder
    });
  };

  ageHanlder = e => {
    e.stopPropagation();
    const age = e.target.value;
    if (this.state.ageError) {
      this.setState({
        age: age,
        ageError: ""
      });
    } else {
      this.setState({
        age: age
      });
    }
  };

  otpHandler = e => {
    e.stopPropagation();
    this.setState({
      otp: e.target.value
    });

    if (this.state.otpError) {
      this.setState({
        otpError: ""
      });
    }
  };

  loginnumberHandler = e => {
    e.stopPropagation();
    if (isNaN(e.target.value) === false) {
      this.setState({
        loginnumber: e.target.value
      });
    }

    if (this.state.loginnumberError) {
      this.setState({
        loginnumberError: ""
      });
    }
  };

  render() {
    return (
      <div onClick={this.handleValidation} className="signUpCpmponent">
        <Header navigateToHome={this.navigateToHome} />
        <div className="formArea">
          <ul>
            <li
              id="signUpli"
              className={this.state.currentState ? "" : "active"}
            >
              Sign Up
            </li>
            <li
              className={this.state.currentState ? "active" : ""}
              id="loginli"
            >
              Login
            </li>
          </ul>
          {this.state.currentState ? (
            <Login
              signupOtp={this.state.signupOtp}
              loginnumberHandler={this.loginnumberHandler}
              loginnumberError={this.state.loginnumberError}
              loginnumber={this.state.loginnumber}
              otpHandler={this.otpHandler}
              otpError={this.state.otpError}
              otp={this.state.otp}
            />
          ) : this.state.signupOtp ? (
            <Verify
              otpError={this.state.otpError}
              otp={this.state.otp}
              otpHandler={this.otpHandler}
            />
          ) : (
            <Register
              name1Handler={this.name1Handler}
              phoneNumber1={this.state.phoneNumber1}
              name1={this.state.name1}
              phonenumberHandler={this.phonenumberHandler}
              age={this.state.age}
              sex={this.state.sex}
              location={this.state.location}
              ageHanlder={this.ageHanlder}
              locationHandler={this.locationHandler}
              genderHandler={this.genderHandler}
              name1Error={this.state.name1Error}
              phoneNumber1Error={this.state.phoneNumber1Error}
              locationError={this.state.locationError}
              ageError={this.state.ageError}
              genderError={this.state.genderError}
            />
          )}
        </div>
        {/* <ContactUs /> */}
      </div>
    );
  }
}
export default withRouter(Signup);

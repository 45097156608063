import React, { Component } from "react";
import { withRouter } from "react-router";
import api from "../../utils/api";
class SplashScreen extends Component {
  async componentDidMount() {
    let isHomeRoute = false;
    let campaign_url_fetch = "";
    if (
      window.location.href === "https://dbfu70mjbc9gd.cloudfront.net" ||
      window.location.href === "http://dbfu70mjbc9gd.cloudfront.net" ||
      window.location.href === "https://dbfu70mjbc9gd.cloudfront.net/" ||
      window.location.href === "http://dbfu70mjbc9gd.cloudfront.net/" ||
      window.location.href === "http://www.wineasy.in" ||
      window.location.href === "https://www.wineasy.in" ||
      window.location.href === "http://www.wineasy.in/" ||
      window.location.href === "https://www.wineasy.in/" ||
      window.location.href === "https://wineasy.in" ||
      window.location.href === "https://wineasy.in/" ||
      window.location.href === "http://localhost:3000/" ||
      window.location.href ===
        "http://nostra-toi-stage.s3-website.ap-south-1.amazonaws.com" ||
      window.location.href ===
        "http://nostra-toi-stage.s3-website.ap-south-1.amazonaws.com/"
    ) {
      this.props.history.replace("selectLocation");
    } else {
      localStorage.setItem("campaign_url", window.location.href);
      const action = Object.assign({});
      action.handler = "V4_UTILS_CHECK_VALID_URL";
      action.payload = {
        campaign_url: window.location.href
      };
      const response = await api(action);
      if (response.status === 200) {
        console.log("checkValidURL success");
        switch (response.data.screenName) {
          case "quizScreen":
            this.props.history.replace("play");
            break;
          case "rightAnswerWonScreen":
            this.props.history.replace("coupons");
            break;
          case "rightAnswerLostScreen":
            this.props.history.replace("coupons");
            break;
          case "rightAnswerScreen":
            this.props.history.replace("coupons");
            break;
          case "wrongAnswerScreen":
            this.props.history.replace("coupons");
            break;
          case "brokenScreen":
            this.props.history.replace("wrongUrl");
            break;
          case "expiryScreen":
            this.props.history.replace("endquiz");
            break;
        }
      } else if (response.status === 401) {
        console.log("invalid auth");
        this.props.history.replace("Signup");
      } else {
        console.log("checkValidURL fails");
        this.props.history.replace("selectLocation");
      }
    }
  }
  // }
  render() {
    return (
      <div className="splashScreen">
        <p>Loading...</p>
      </div>
    );
  }
}

export default withRouter(SplashScreen);

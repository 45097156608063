import React, { Component } from "react";
import { withRouter } from "react-router";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="root_loginTerms">
        <div className="hdr_loginTerms">PRIVACY POLICY</div>
        <div className="body_loginTerms">
          This Privacy Policy explains our policy regarding the collection, use,
          disclosure and transfer of your information by Bennett, Coleman & Co.
          Ltd (hereinafter referred to as “BCCL”). This privacy policy forms
          shall be read along with Terms of Use for availing services offered
          through the website www.wineasy.in or its mobile application
          (collectively referred to as the ‘Site’). Capitalized terms which have
          been used here but are not defined herein shall have the same meaning
          as attributed to them in the Terms of Use.
          <br />
          <br />
          As we update, improve and expand the services, this policy may change,
          so please refer back to it periodically. By accessing the Site or
          otherwise using the services, you consent to collection, storage, and
          use of the personal information you provide (including any changes
          thereto as provided by you) for any of the services that we offer.
          <br />
          <br />
          BCCL respects the privacy of the users of the Site your right to
          privacy is of paramount importance to BCCL. The information about the
          user as collected by BCCL is: (a) information supplied by users and
          (b) information automatically tracked while navigation (c) information
          collected from any other source (collectively referred to as
          Information).
          <br />
          <br />
          1. INFORMATION RECEIVED, COLLECTED AND STORED BY THE BCCL
          <br />
          A. INFORMATION SUPPLIED BY USERS
          <br />
          Registration data:
          <br /> When you register on the Site for the service, we ask that you
          provide basic contact Information such as your name, sex, age,
          address, pin code, contact number and email address etc. When you
          register using your other accounts like on Facebook, Twitter, Gmail
          etc. we shall retrieve Information from such account to continue to
          interact with you and to continue providing the Services.
          <br /> Voluntary information:
          <br /> We may collect additional information at other times, including
          but not limited to, when you provide feedback, change your content or
          email preferences, respond to a survey, or communicate with us.
          <br />
          <br /> B. INFORMATION AUTOMATICALLY COLLECTED/ TRACKED WHILE
          NAVIGATION <br />
          Cookies
          <br /> To improve the responsiveness of the "Site" for our users, we
          may use "cookies", or similar electronic tools to collect information
          to assign each visitor a unique, random number as a User
          Identification (User ID) to understand the user's individual interests
          using the identified computer. Unless you voluntarily identify
          yourself (through registration, for example), we will have no way of
          knowing who you are, even if we assign a cookie to your computer. The
          only personal information a cookie can contain is information you
          supply. A cookie cannot read data off your hard drive. Our advertisers
          may also assign their own cookies to your browser (if you click on
          their ads), a process that we do not control. We receive and store
          certain types of information whenever you interact with us via the
          Site <br />
          <br />
          Opting out <br />
          If a user opts out using the Ads Settings, the unique DoubleClick
          cookie ID on the user's browser is overwritten with the phrase
          "OPT_OUT". Because there is no longer a unique cookie ID, the opt-out
          cookie can't be associated with a particular browser.
          <br />
          <br /> Log File Information
          <br /> We automatically collect limited information about your
          computer's connection to the Internet, mobile number, including your
          IP address, when you visit our Site. Your IP address is a number that
          lets computers attached to the Internet know where to send you data --
          such as the pages you view. We automatically receive and log
          information from your browser, including your IP address, your
          computer's name, your operating system, browser type and version, CPU
          speed, and connection speed. We may also collect log information from
          your device, including your location, IP address, your device's name,
          device's serial number or unique identification number (e.g.. UDiD on
          your iOS device), your device operating system, browser type and
          version, CPU speed, and connection speed etc. <br />
          <br />
          Clear GIFs
          <br /> We may use "clear GIFs" (Web Beacons) to track the online usage
          patterns of our users in an anonymous manner, without personally
          identifying the user. We may also use clear GIFs in HTML-based emails
          sent to our users to track which emails are opened by recipients. We
          use this information to inter-alia deliver our web pages to you upon
          request, to tailor our Site to the interests of our users, to measure
          traffic within our Site to improve quality, functionality and
          interactivity and let advertisers know the geographic locations from
          where our visitors come. <br />
          <br />
          Information from other Sources:
          <br /> We may receive information about you from other sources, add it
          to our account information and treat it in accordance with this
          policy. If you provide information to the platform provider or other
          partner, whom we provide services, your account information and order
          information may be passed on to us. We may obtain updated contact
          information from third parties in order to correct our records and
          fulfil the Services or to communicate with you <br />
          <br />
          Demographic and purchase information: <br />
          We may reference other sources of demographic and other information in
          order to provide you with more targeted communications and promotions.
          We use Google Analytics, among others, to track the user behaviour on
          our website. Google Analytics specifically has been enabled to support
          display advertising towards helping us gain understanding of our
          users' Demographics and Interests. The reports are anonymous and
          cannot be associated with any individual personally identifiable
          information that you may have shared with us. You can opt-out of
          Google Analytics for Display Advertising and customize Google Display
          Network ads using the Ads Settings options provided by Google.
          <br />
          <br /> 2. LINKS TO THIRD PARTY SITES
          <br /> The Application may include links to other websites or
          applications. Such websites or applications are governed by their
          respective privacy policies, which are beyond our control. Once you
          leave our servers (you can tell where you are by checking the URL in
          the location bar on your browser), use of any information you provide
          is governed by the privacy policy of the operator of the application,
          website you are visiting. That policy may differ from ours. If you
          can't find the privacy policy of any of these sites via a link from
          the application's/website homepage, you should contact the
          application/website owners directly for more information.
          <br />
          <br />
          By visiting the Site and entering your data to know more about details
          of any product or services displayed on the Site, you hereby
          authorizes us to share your data with our advertiser in order to give
          you more information about the product or goods or services. Apart
          from the same, we do not provide any personally identifiable
          information to third party websites / advertisers / ad-servers without
          your consent. <br />
          <br />
          3. INFORMATION USED BY THE COMPANY AND YOUR RIGHTS <br />
          The Information as supplied by the users enables us to improve the
          services and provide you the most user-friendly experience. In some
          cases/provision of certain service(s) we may require your contact
          address as well. All required information is service dependent and the
          BCCL may use the above said user Information to, maintain, protect,
          and improve the services and for developing new services. We may also
          use your email address or other personally identifiable information to
          send commercial or marketing messages without your consent [with an
          option to subscribe / unsubscribe]. We may, however, use your email
          address without further consent for non-marketing or administrative
          purposes (such as notifying you of major changes, for customer service
          purposes, billing, etc.), recommending various products or services
          including those of third parties (with an option to opt out), dealing
          with requests, enquiries and complaints and customer related
          activities. <br />
          <br />
          BCCL may, if you so choose, send direct mailers to you at the address
          given by you. You have the option to 'opt-out' of this direct mailer
          by way of links provided at the bottom of each mailer. BCCL respect
          your privacy and in the event that you choose to not receive such
          mailers, BCCL will take all steps to remove you from the list.
          <br />
          <br /> In case you choose to decline to submit personally identifiable
          information on the Site, we may not be able to provide the services to
          you. We will make reasonable efforts to notify you of the same at the
          time of opening your account. In any case, we will not be liable and
          or responsible for the denial of certain services to you for lack of
          you providing the necessary personal information.
          <br />
          <br /> When you register with the Site, we contact you from time to
          time about updation of your personal information to provide the users
          such features that we believe may benefit / interest you. <br />
          <br />
          4. INFORMATION SHARING <br />
          BCCL shall share your information with any third party without
          obtaining the prior consent of the user/you in the following limited
          circumstances: a) When it is requested or required by law or by any
          court or governmental agency or authority to disclose, for the purpose
          of verification of identity, or for the prevention, detection,
          investigation including cyber incidents, or for prosecution and
          punishment of offences. These disclosures are made in good faith and
          belief that such disclosure is reasonably necessary for enforcing
          these Terms or for complying with the applicable laws and regulations.
          b) BCCL proposes to share such information within its group companies
          and officers and employees of such group companies for the purpose of
          processing personal information on its behalf for provisioning of the
          services for which such Information was provided. We also ensure that
          these recipients of such information agree to process such information
          based on our instructions and in compliance with this Privacy Policy
          and any other appropriate confidentiality and security measures. c)
          BCCL may present information to our advertisers - to help them
          understand our audience and confirm the value of advertising on our
          Site– however it is usually in the form of aggregated statistics on
          traffic to various pages within our site. <br />
          <br />
          5. ACCESSING AND UPDATING PERSONAL INFORMATION
          <br /> When you use the Site (or any of its sub sites), we make good
          faith efforts to provide you upon verification, as and when requested
          by you, with access to your personal information and shall further
          ensure that any personal information or sensitive personal data or
          information found to be inaccurate or deficient shall be corrected or
          amended as feasible, subject to any requirement for such personal
          information or sensitive personal data or information to be retained
          by law or for legitimate business purposes. We ask individual users to
          identify themselves and the information requested to be accessed,
          corrected or removed before processing such requests, and we may
          decline to process requests that are unreasonably repetitive or
          systematic, jeopardize the privacy of others, or would be extremely
          impractical (for instance, requests concerning information residing on
          backup tapes), or for which access is not otherwise required. In any
          case, where we provide information access and correction, we perform
          this service free of charge, except if doing so would require a
          disproportionate effort. Because of the way we maintain certain
          services, after you delete your information, residual copies may take
          a period of time before they are deleted from our active servers and
          may remain in our backup systems.
          <br />
          <br /> 6. INFORMATION SECURITY <br />
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure or destruction of
          data. These include internal reviews of our data collection, storage
          and processing practices and security measures, including appropriate
          encryption and physical security measures to guard against
          unauthorized access to systems where we store personal data. All
          information gathered on the Site is securely stored within the BCCL
          controlled database. The database is stored on servers secured behind
          a firewall; access to the servers is password-protected and is
          strictly limited. However, as effective as our security measures are,
          no security system is impenetrable. We cannot guarantee the security
          of our database, nor can we guarantee that information you supply will
          not be intercepted while being transmitted to us over the Internet.
          And, of course, any information you include in a posting to the
          discussion areas is available to anyone with Internet access. We use
          third-party advertising companies to serve ads when you visit or use
          our website, mobile application or services. These companies may use
          information (not including your name, address, email address or
          telephone number) about your visits or use to particular website,
          mobile application or services, in order to provide advertisements
          about goods and services of interest to you. <br />
          <br />
          7. UPDATES / CHANGES <br />
          The internet is an ever evolving medium. We may alter our privacy
          policy from time to time to incorporate necessary changes in
          technology, applicable law or any other variant. In any case, we
          reserve the right to change (at any point of time) the terms of this
          Privacy Policy or the Terms of Use. Any changes we make will be
          effective immediately on notice, which we may give by posting the new
          policy on the "Site". Your use of the Site or services offered through
          the Site after such notice will be deemed acceptance of such changes.
          We may also make reasonable efforts to inform you via electronic mail.
          In any case, you are advised to review this Privacy Policy
          periodically on the "Site") to ensure that you are aware of the latest
          version.
          <br />
          <br /> 8. RETENTION OF INFORMATION <br />
          BCCL will not retain the Information longer than is necessary to
          fulfil the purposes for which it was collected or as required by
          applicable laws or keep you updated with BCCL’s promotions where you
          have asked us to do so and provide you with the best user experience.
          When a users’ account is terminated or expired, all Personal
          Information collected through the Site will be deleted or in some case
          encrypted, as required by applicable law. <br />
          <br />
          9. QUESTIONS / GRIEVANCE REDRESSAL
          <br /> Redressal Mechanism: Any complaints, abuse or concerns with
          regards to the processing of information provided by you or breach of
          these terms shall be immediately informed to the designated Grievance
          Officer as mentioned below via in writing or through email signed with
          the electronic signature to Grievance Officer at
          timesresponse@timesgroup.com Grievance Officer Bennett, Coleman & Co.
          Ltd. Times Of India Building,Dr.D.N.Road, Mumbai- 400 001. Ph No.
          91-22-66353535 We request you to please provide the following
          information in your complaint:- a) Identification of the information
          provided by you; b) Clear statement as to whether the information is
          personal information or sensitive personal information; c) Your
          address, telephone number or e-mail address; d) A statement that you
          have a good-faith belief that the information has been processed
          incorrectly or disclosed without authorization, as the case may be; e)
          A statement, under penalty of perjury, that the information in the
          notice is accurate, and that the information being complained about
          belongs to you; f) You may also contact us in case you have any
          questions / suggestions about the Privacy Policy using the contact
          information mentioned above. BCCL shall not be responsible for any
          communication, if addressed, to any non-designated person in this
          regard
        </div>
      </div>
    );
  }
}
export default withRouter(PrivacyPolicy);

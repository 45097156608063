import React, { Component } from "react";
import {
  ResultsWon,
  AwaitingWinnersWrongAnswer,
  AwaitingWinnersCorrectAnswer,
  DidNotWin
} from "./components";
import { withRouter } from "react-router";
import api from "../../utils/api";
import { ContactUs, PrivacyPolicy } from "../common";
class Results extends Component {
  constructor() {
    super();
    this.state = {
      resultsComponent: "",
      resultsData: ""
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      let campaign_url = window.location.href;
      campaign_url = campaign_url.replace("/results", "");
      localStorage.setItem("campaign_url", campaign_url);
      campaign_url = localStorage.getItem("campaign_url");
      const action = Object.assign({});
      action.handler = "V4_UTILS_QUIZ_RESULT";
      action.payload = {
        campaign_url: campaign_url
      };
      const response = await api(action);
      if (response.status === 200) {
        if (response.data.quizResult && response.data.quizResult.screenName) {
          this.setState({
            resultsData: response.data,
            resultsComponent: response.data.quizResult.screenName
          });

          if (response.data.quizResult.screenName === "quizScreen") {
            this.props.history.replace("/play");
          }
        }
      } else {
        this.props.history.replace("/homeScreen");
      }
    } else {
      let campaign_url = window.location.href;
      campaign_url = campaign_url.replace("/results", "");
      localStorage.setItem("campaign_url", campaign_url);
      localStorage.setItem("unauthResults", true);
      this.props.history.replace("/Signup");
    }
    console.log(this.props.history);
  }

  navigateToHome = () => {
    this.props.history.replace("/SelectLocation");
  };

  downloadAPK = async () => {
    const action = Object.assign({});
    action.handler = "V1_GET_LATEST_APK";
    const response = await api(action);
    if (response.status === 200) {
      window.location.href = response.data[0].app_link;
    }
  };

  navigateToTerms = () => {
    // window.open("https://www.wineasy.in/couponTerms");
    this.props.history.replace("/couponTerms");
  };

  render() {
    const paper_link =
      this.state.resultsData &&
      this.state.resultsData.campaign &&
      this.state.resultsData.campaign.paper_link;
    if (this.state.resultsComponent === "rightAnswerWonScreen") {
      return (
        <div>
          <ResultsWon
            date={
              this.state.resultsData &&
              this.state.resultsData.quizResult &&
              this.state.resultsData.quizResult.data &&
              this.state.resultsData.quizResult.data.createdAt
            }
            campaign_url={
              this.state.resultsData &&
              this.state.resultsData.campaign &&
              this.state.resultsData.campaign.campaign_url
            }
            handleAPKDownload={this.downloadAPK}
            resultsData={this.state.resultsData}
            navigateToTerms={this.navigateToTerms}
            navigateToHome={this.navigateToHome}
          />
          <ContactUs paper_link={paper_link} />
          <PrivacyPolicy />
        </div>
      );
    }
    if (this.state.resultsComponent === "rightAnswerLostScreen") {
      return (
        <div>
          <DidNotWin
            date={
              this.state.resultsData &&
              this.state.resultsData.quizResult &&
              this.state.resultsData.quizResult.data &&
              this.state.resultsData.quizResult.data.createdAt
            }
            campaign_url={
              this.state.resultsData &&
              this.state.resultsData.campaign &&
              this.state.resultsData.campaign.campaign_url
            }
            handleAPKDownload={this.downloadAPK}
            resultsData={this.state.resultsData}
            navigateToHome={this.navigateToHome}
          />
          <ContactUs paper_link={paper_link} />
          <PrivacyPolicy />
        </div>
      );
    }
    if (this.state.resultsComponent === "rightAnswerScreen") {
      return (
        <div>
          <AwaitingWinnersCorrectAnswer
            date={
              this.state.resultsData &&
              this.state.resultsData.quizResult &&
              this.state.resultsData.quizResult.data &&
              this.state.resultsData.quizResult.data.createdAt
            }
            campaign_url={
              this.state.resultsData &&
              this.state.resultsData.campaign &&
              this.state.resultsData.campaign.campaign_url
            }
            handleAPKDownload={this.downloadAPK}
            resultsData={this.state.resultsData}
            navigateToHome={this.navigateToHome}
          />
          <ContactUs paper_link={paper_link} />
          <PrivacyPolicy />
        </div>
      );
    }
    if (this.state.resultsComponent === "wrongAnswerScreen") {
      return (
        <div>
          <AwaitingWinnersWrongAnswer
            date={
              this.state.resultsData &&
              this.state.resultsData.quizResult &&
              this.state.resultsData.quizResult.data &&
              this.state.resultsData.quizResult.data.createdAt
            }
            campaign_url={
              this.state.resultsData &&
              this.state.resultsData.campaign &&
              this.state.resultsData.campaign.campaign_url
            }
            handleAPKDownload={this.downloadAPK}
            resultsData={this.state.resultsData}
            navigateToHome={this.navigateToHome}
          />
          <ContactUs paper_link={paper_link} />
          <PrivacyPolicy />
        </div>
      );
    }
    return (
      <div style={{ margin: "3rem auto", color: "#fff" }}>
        Please Wait while we fetch the results
      </div>
    );
  }
}
export default withRouter(Results);

import React, { Component } from "react";
import { withRouter } from "react-router";
import { ContactUs } from "../common/index";
import { Header } from "../signUp/components";
import "./style.scss";
import api from "../../utils/api";

class EndQuiz extends Component {
  handleAPKDownload = async () => {
    const action = Object.assign({});
    action.handler = "V1_GET_LATEST_APK";
    const response = await api(action);
    if (response.status === 200) {
      window.location.href = response.data[0].app_link;
    }
  };
  navigateToHome = () => {
    this.props.history.replace("/SelectLocation");
  };

  render() {
    return (
      <div className="endQuiz">
        <Header navigateToHome={this.navigateToHome} />
        <div className="timerArea">
          <div className="timerIcon">
            <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/timeup.png" />
          </div>
          <p>
            This quiz has ended! Please scan a new quiz in order to play &amp;
            win prizes
          </p>
        </div>
        <div className="downLoadAreaEnd" onClick={this.handleAPKDownload}>
          <img src="https://cdn-images.spcafe.in/img/es3/Gamewebsite/website/ToI/Nostra_Download-min.png" />
        </div>
        {/* <ContactUs /> */}
      </div>
    );
  }
}

export default withRouter(EndQuiz);
